import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

type ConfirmPreviewSignup = {
  success: boolean;
  data: boolean;
  message: string;
  messageType: string;
};

const API_PATH = process.env.GATSBY_API_PATH;
const subscriptionBaseQuery = fetchBaseQuery({
  baseUrl: API_PATH,
  prepareHeaders: (headers) => {
    return headers;
  },
});

const usersApi = createApi({
  reducerPath: 'users',
  baseQuery: subscriptionBaseQuery,
  endpoints: (builder) => ({

    specialPreviewSignup: builder.mutation<
      ConfirmPreviewSignup,
      {
        firstName: string;
        lastName: string;
        emailAddress: string;
        organizationName: string;
        receiveUpdates: boolean;
      }
    >({
      query: ({
        firstName,
        lastName,
        emailAddress,
        organizationName,
        receiveUpdates,
      }) => ({
        url: '/users/special-preview-signup',
        method: 'POST',
        body: {
          firstName,
          lastName,
          emailAddress,
          organizationName,
          receiveUpdates,
        },
      }),
      transformResponse: (response: { data: ConfirmPreviewSignup }) =>
        response.data,
    }),
  }),
});

export { usersApi };
